<template>
  <div
    :style="
      `display:inline-block;background: url('${baseUrl}assets/sprite.png') ${left}px ${top}px;
  width: ${width}px; height: ${height}px`
    "
  />
</template>

<script>
import spriteSheet from '../../public/assets/sprite.json';

export default {
  name: 'SpriteIcon',
  props: {
    icon: {
      type: String
    }
  },

  data() {
    return {
      baseUrl: process.env.BASE_URL
    };
  },
  computed: {
    left() {
      return -spriteSheet[this.icon].x;
    },
    top() {
      return -spriteSheet[this.icon].y;
    },
    width() {
      return spriteSheet[this.icon].width;
    },
    height() {
      return spriteSheet[this.icon].height;
    }
  }
};
</script>
